import { useDialog } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { AllurionSearchbar } from 'src/components/ui/AllurionSearchbar';
import { Loader } from 'src/components/ui/Loader';
import { PatientsAccess } from 'src/domain/patient/Patients';
import { Provider } from 'src/domain/Provider';
import { isPatientGroupingEnabled } from 'src/domain/settings';
import { isAdmin } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useDebounceCallback } from 'src/hooks/useDebounceCallback';
import { useProviderAccess } from 'src/hooks/useProviderAccess';
import { Logger } from 'src/services/Logger';

import { Container, InnerContainer } from '../shared-page-elements';

import { EditProvidersModal } from './EditProviderModal/EditProvidersModal';
import { ManagePatientsDropdown } from './ManagePatientsDropdown';
import { ManagePatientsSelectors } from './ManagePatientsSelectors';
import { ManagePatientsTable } from './ManagePatientsTable';

export default function ManagePatientsPage() {
  const intl = useIntl();
  const { user } = useCurrentUser();
  const { clinicId } = useParams();
  const [sortBy, setSortBy] = useState<string>('+lastname');

  const { providers, isLoading: isProvidersLoading } = useClinicProviders(clinicId);
  const [patientFilter, setPatientFilter] = useState('');
  const [selectedPatients, setSelectedPatients] = useState<PatientsAccess[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<Provider>();
  const defaultPageSize = 10;
  const [page, setPage] = useState<{ index: number; size: number }>({
    index: 1,
    size: defaultPageSize,
  });
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const {
    patients,
    totalPages,
    isLoading: isLoadingPatients,
    updateProviderAccess,
  } = useProviderAccess({
    clinicId,
    page,
    selectedProvider,
    patientFilter,
    order: sortBy[0] === '+' ? 'asc' : 'desc',
    orderby: sortBy.slice(1) === 'firstname' ? 'name' : sortBy.slice(1),
  });
  const { toInvitePatientPage: toAddPatientPage } = useAppNavigate();
  const { settings, isLoading: isLoadingSettings } = useClinicSettings(clinicId);
  const { openDialog, Dialog } = useDialog<ComponentProps<typeof EditProvidersModal>>({
    content: ({ close, ...contentProps }) => {
      return (
        <EditProvidersModal
          {...{
            ...contentProps,
            onCancel: () => close?.(),
            onConfirm: (...props) => {
              contentProps.onConfirm(...props);
              close?.();
            },
          }}
        />
      );
    },
  });
  const providerHasAccess = isAdmin(user) && isPatientGroupingEnabled(settings);

  const cleanupSelectors = () => {
    setSelectedProvider(undefined);
    setSelectedPatients([]);
    setPage((page) => ({ ...page, index: 1 }));
  };

  const onPatientsFilter = (e: any) => {
    setPatientFilter(e.target.value);
    setPage({ ...page, index: 1 });
  };

  const debounceCallback = useDebounceCallback(onPatientsFilter, 1500);

  const onInvitePatient = () => {
    toAddPatientPage(clinicId!);
  };
  const onSelectProviders = (p: Provider) => setSelectedProvider(p.ClinicID ? p : undefined);

  const onAddPatient = () => {
    openDialog({
      title: intl.formatMessage({
        id: 'provider-access.title.update-providers',
        defaultMessage: 'Edit Patient Providers',
      }),
      contentProps: {
        selectedPatients: selectedPatients,
        providers: providers,
        onCancel: () => {},
        onConfirm: onConfirm,
      },
    });
  };

  const onCheckPatient = (pat?: PatientsAccess, isAll?: boolean) => {
    if (isAll === true) {
      setSelectedPatients(patients);
    } else if (isAll === false) {
      setSelectedPatients([]);
    } else if (pat) {
      setSelectedPatients((arr) =>
        arr.includes(pat) ? arr.filter((i) => i !== pat) : [...arr, pat]
      );
    }
  };

  const onConfirm = async (
    patientsIds: number[],
    providersIds: number[],
    onSuccess = () => {},
    onFailure = () => {}
  ) => {
    const handleSuccess = () => {
      onSuccess();
      cleanupSelectors();
    };

    try {
      await updateProviderAccess(patientsIds, providersIds, handleSuccess, onFailure);
      trackFormSuccess('invite-patient');
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      trackFormError('invite-patient', { error: errorMessage });
    }
  };

  const onSort = (nextSortBy: string) => {
    setSortBy(nextSortBy);
  };

  const isLoading = isProvidersLoading || isLoadingPatients;
  const patientsCount = selectedPatients?.length;

  if ((!providerHasAccess || !clinicId) && !isLoadingSettings) {
    throw new Error('Forbidden');
  }

  return (
    <>
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'organize-patients.header',
          description: 'Organize Patients page header',
          defaultMessage: 'Organize Patients',
        })}
        button={{
          label: intl.formatMessage({
            id: 'manage-patients.add-patient-btn',
            defaultMessage: 'Invite Patient',
          }),
          onClick: onInvitePatient,
          trackLabel: 'invite-patient',
        }}
      />

      <Container>
        <Dialog />
        <Seo title="Organize Patients" />
        <InnerContainer>
          <SelectorsContainer>
            <StyledPatientsSelectors
              providers={providers}
              onSelectProviders={onSelectProviders}
              selectedProvider={selectedProvider}
            />
            <PatientsFilter
              onChange={debounceCallback}
              placeholder={intl.formatMessage({
                id: 'manage-patients.search',
                defaultMessage: 'Search',
              })}
            />
          </SelectorsContainer>

          <TableDescription>
            {intl.formatMessage({
              id: 'manage-patients.tableDescription',
              defaultMessage: 'Select patients on the table below to edit the providers:',
            })}
          </TableDescription>
          <ManagePatientsDropdown patientsCount={patientsCount} onAddPatient={onAddPatient} />
          <ManagePatientsTable
            patients={patients}
            totalPages={totalPages}
            onCheckPatient={onCheckPatient}
            selectedPatients={selectedPatients}
            page={page}
            setPage={setPage}
            defaultPageSize={defaultPageSize}
            sortBy={sortBy}
            onSort={onSort}
            isLoading={isLoading}
          />
          <Loader cover isLoading={isLoading} />
        </InnerContainer>
      </Container>
    </>
  );
}

const PatientsFilter = styled(AllurionSearchbar)`
  flex: 1;
  max-width: 335px;
  min-width: 300px;
  flex-grow: 1;
  height: 44px;
  margin-top: auto;
  padding: 10px, 10px, 10px, 20px;
  gap: 31px;
`;

const StyledPatientsSelectors = styled(ManagePatientsSelectors)`
  flex: 1;
  max-width: 319px;
`;

const SelectorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: ${({ theme }) => theme.media.MAX_WIDTH}px;
`;

const TableDescription = styled.div`
  font-size: 16px;
  margin: 48px 0 0 0;
  color: ${({ theme }) => theme.colors.Primary};
`;
