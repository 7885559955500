import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';
import { Participant } from '@twilio/conversations/lib/participant';
import { useEffect, useState, useCallback } from 'react';

import { useTrackEvent } from 'src/analytics/analytics';
import { Logger } from 'src/services/Logger';

import TwilioConversationsService from './TwilioConversationsService';

type Props = {
  identity?: string;
  patientIdentity: string;
  friendlyName?: string;
  onMessageAdded?: Function;
  onTypingStarted?: Function;
  onTypingEnded?: Function;
};

export function useTwilioConversation({ identity, patientIdentity }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversation, setConversation] = useState<Conversation | undefined>(undefined);
  const [typing, setTyping] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { trackTwilioFailedMessage, trackTwilioChatFailure } = useTrackEvent();

  const sendMessage = async (
    message: string | FormData | Conversation.SendMediaOptions | null,
    attributes?: any,
    emailOptions?: Conversation.SendEmailOptions
  ): Promise<number | undefined> => {
    try {
      const res = await conversation?.sendMessage(message, attributes, emailOptions);

      return res;
    } catch (error) {
      trackTwilioFailedMessage({ error });
    }
  };

  const getParticipants = async () => {
    await conversation?.getParticipants();
  };

  const getMessagesCount = useCallback(async () => {
    const nums = await conversation?.getMessagesCount();

    return nums;
  }, [conversation]);

  const getAllMessages = async (m: any) => {
    let tmpMsg = m;
    const allMessages = [];

    allMessages.push(...(m?.items ?? []));
    for (; tmpMsg?.hasNextPage; ) {
      // eslint-disable-next-line no-await-in-loop
      tmpMsg = await tmpMsg?.nextPage();
      allMessages.push(...(tmpMsg?.items ?? []));
    }

    return allMessages;
  };

  const onMessageAdded = async (message: Message) => {
    setMessages((m) => [...m, message]);
    // This even triggers when either the patient or the coach add a messages
    // This helps identifies if the message it's from the the coach
    if (message.author !== conversation?.uniqueName) {
      // If it is from the coach we update the last read message index
      await conversation?.updateLastReadMessageIndex(message.index);
    }
  };

  const onTypingStarted = (member: Participant) => {
    setTyping(member?.identity);
  };

  const onTypingEnded = () => {
    setTyping('');
  };

  const joinConversation = useCallback(async (): Promise<void> => {
    setLoading(true);
    const nums = await getMessagesCount();
    // TODO: enable lazy-loading by using pagination
    const msgs = await conversation?.getMessages(nums, 0, 'forward');
    const allMsgs = await getAllMessages(msgs);

    if (conversation) {
      if (conversation?.status !== 'joined') {
        await conversation?.join();
      }
      conversation?.on('messageAdded', onMessageAdded);
      conversation?.on('typingStarted', onTypingStarted);
      conversation?.on('typingEnded', onTypingEnded);
      setMessages(allMsgs);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, getMessagesCount]);

  const getParticipantByIdentity = async (identity: string) => {
    const participant = await conversation?.getParticipantByIdentity(identity);

    return participant;
  };

  const setAllMessagesRead = useCallback(async () => {
    const count = await conversation?.setAllMessagesRead();

    return count;
  }, [conversation]);

  const updateAttributes = async (attr: any) => {
    const conv = await conversation?.updateAttributes(attr);

    return conv;
  };

  const getAttributes = async () => {
    const attr = await conversation?.getAttributes();

    return attr;
  };

  const getOpponentLastReadMessageIndex = (p: Participant | undefined) => p?.lastReadMessageIndex;

  const fetchConv = useCallback(async () => {
    if (!identity || !patientIdentity) {
      return;
    }

    try {
      setError('');
      const conv = await TwilioConversationsService.waitForConversation(identity, patientIdentity);

      if (!conv) {
        setError('Conversation not found');

        return;
      }

      setConversation(conv);
    } catch (error) {
      Logger.captureException(error);
      setError(error instanceof Error ? error.message : `${error}`);
    }
  }, [identity, patientIdentity]);

  useEffect(() => {
    if (conversation || !patientIdentity) {
      return;
    }

    fetchConv();
  }, [conversation, fetchConv, patientIdentity]);

  useEffect(() => {
    if (conversation) {
      joinConversation().catch((error) => {
        trackTwilioChatFailure({ error });
      });
    }

    return () => {
      conversation?.off('messageAdded', onMessageAdded);
      conversation?.off('typingStarted', onTypingStarted);
      conversation?.off('typingEnded', onTypingEnded);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, joinConversation]);

  return {
    messages,
    loading,
    sendMessage,
    typing,
    conversation,
    error,
    getParticipantByIdentity,
    getParticipants,
    getMessagesCount,
    updateAttributes,
    setAllMessagesRead,
    getAttributes,
    getOpponentLastReadMessageIndex,
  };
}
