import { AccountNavbar, ExitIcon, UserIcon, MailIcon, ClinicsIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ClinicSettingsFeatures } from 'src/domain/settings';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useHasMessageNotifications } from 'src/hooks/useMessageNotifications';
import { useUserClinicsSettings } from 'src/hooks/useUserClinicsSettings';
import * as appRoutes from 'src/utils/appRoutes';

import styles from './Header.module.scss';

export function Header() {
  const { logout } = useCurrentUser();
  const { toHomePage } = useAppNavigate();
  const navigate = useNavigate();
  const { hasAccessToFeature } = useUserClinicsSettings();
  const intl = useIntl();

  const handleLogout = () => {
    logout({ replace: true });
  };

  //check if any clinic from the user has messages
  const isMessageEnabled = hasAccessToFeature(ClinicSettingsFeatures.Messages);

  const { data: thereIsNewMessages } = useHasMessageNotifications();

  const headerIcons = [
    {
      id: 'home',
      icon: <ClinicsIcon />,
      onClick: () => toHomePage(),
      label: intl.formatMessage({ id: 'header.clinics', defaultMessage: 'Clinics' }),
    },
    {
      id: 'messages',
      icon: (
        <div className={styles.newMessage}>
          <MailIcon />
          {thereIsNewMessages && <span className={styles.badge} data-cy="new-message-badge" />}
        </div>
      ),
      onClick: () => navigate(appRoutes.messageNotifications),
      label: intl.formatMessage({ id: 'header.messages', defaultMessage: 'New Messages' }),
      hide: !isMessageEnabled,
    },
    {
      id: 'profile',
      icon: <UserIcon />,
      onClick: () => navigate(appRoutes.profileRoute),
      label: intl.formatMessage({ id: 'header.profile', defaultMessage: 'Your Account' }),
    },
    {
      id: 'logout',
      icon: <ExitIcon />,
      onClick: handleLogout,
      label: intl.formatMessage({ id: 'header.logout', defaultMessage: 'Log Out' }),
    },
  ]
    .filter((icon) => !icon.hide) //hide icons that have hide property
    .map(({ hide, ...icon }) => icon); //remove hide property from the icon

  return (
    <div className={styles.container}>
      <AccountNavbar iconButtons={headerIcons} onMenuButtonClick={() => toHomePage()} />
    </div>
  );
}
