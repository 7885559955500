import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { ActivePatient } from 'src/domain/patient/Patients';

export const ACTIVE_PATIENTS = (clinicId: string) => `/clinics/${clinicId}/active-patients`;
const ACTIVE_PATIENTS_RANGE_IN_MONTHS = 12;

export function useClinicActivePatients(clinicId: string, patientFilter?: string, suspense = true) {
  const query = {
    numberOfMonths: ACTIVE_PATIENTS_RANGE_IN_MONTHS,
    search: patientFilter || '',
  };

  const url = buildUrl(ACTIVE_PATIENTS(clinicId.toString()), query);
  const { data, error, isValidating, isLoading } = useSWR(
    url,
    (_url) =>
      InsightsApi.get<{ activePatients: ActivePatient[] }>(_url).then((res) => res.activePatients),
    {
      revalidateOnFocus: false,
      suspense,
    }
  );

  return {
    activePatients: data ?? [],
    error,
    isLoading: isValidating || isLoading,
  };
}
