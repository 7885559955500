import { Button, IconButton, InfoIcon } from '@allurion/ui';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { ParseHyperlinks } from 'src/components/ParseHyperlinks';
import { useAppNavigate } from 'src/hooks/useAppNavigate';

import translations from './ErrorBoundary.translations';

import styles from './ErrorBoundaryMessage.module.scss';

type Props = {
  kind?: 'forbidden' | 'notFound' | 'unknown' | 'unauthorized';
};

export function ErrorBoundaryMessage({ kind }: Props) {
  const intl = useIntl();
  const { toHomePage } = useAppNavigate();
  const { pathname } = useLocation();

  const isLoginPage = pathname === '/login';
  const shouldRedirectToLogin = kind === 'unauthorized' && !isLoginPage;

  if (shouldRedirectToLogin) {
    reloadClearCache();

    return null;
  }

  const { titleMessageId, messageMessageId, buttonMessageId, onButtonClick } = getBehaviorByKind();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <IconButton icon={<InfoIcon />} variant="secondary" size="lg" />

        <div className={styles.title}>{intl.formatMessage(titleMessageId)}</div>
        <div className={styles.message}>
          <ParseHyperlinks
            message={intl.formatMessage(messageMessageId)}
            style={{ marginTop: 48, fontFamily: 'GT-America-Standard-Light' }}
          />
        </div>

        <Button label={intl.formatMessage(buttonMessageId)} onClick={onButtonClick} />
      </div>
    </div>
  );

  function reloadClearCache() {
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.href = '/';
    window.location.reload();
  }

  function goHome() {
    toHomePage();
    window.location.reload();
  }

  function getBehaviorByKind() {
    switch (kind) {
      case 'forbidden':
        return {
          titleMessageId: translations.forbiddenErrorTitle,
          messageMessageId: translations.forbiddenErrorMessage,
          buttonMessageId: translations.forbiddenErrorButton,
          onButtonClick: goHome,
        };

      case 'notFound':
        return {
          titleMessageId: translations.notFoundErrorTitle,
          messageMessageId: translations.notFoundErrorMessage,
          buttonMessageId: translations.notFoundErrorButton,
          onButtonClick: goHome,
        };

      default:
        return {
          titleMessageId: translations.errorBoundaryTitle,
          messageMessageId: translations.errorBoundaryMessage,
          buttonMessageId: translations.errorBoundaryReturn,
          onButtonClick: reloadClearCache,
        };
    }
  }
}
