import * as Sentry from '@sentry/react';

import { User } from 'src/domain/User';
import { PROVIDERS_ROLES } from 'src/utils/constants';
import env from 'src/utils/env';

const shouldLogToSentry = env.LOGTO_SENTRY;
const sentryDSN = env.SENTRY_DSN;
const currentCommit = env.CURRENT_COMMIT;

if (shouldLogToSentry && !sentryDSN) {
  throw new Error('Missing SENTRY_DSN value on the enviroment variables');
}

if (shouldLogToSentry) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    sampleRate: 0.7,
    release: currentCommit,
    environment: env.SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.2,
    denyUrls: ['localhost'],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export class Logger {
  static configureScope(user: User) {
    if (shouldLogToSentry) {
      Sentry.setUser({
        username: user.username,
        id: user.providerId!.toString(),
        role: PROVIDERS_ROLES[user.roleId!],
        unitsPreference: user.unitsPreference,
      });
    }
  }

  static captureMessage(message: string) {
    if (shouldLogToSentry) {
      Sentry.captureMessage(message);
    } else {
      // eslint-disable-next-line no-console
      console.info(message);
    }
  }

  static captureException(exception: any) {
    if (shouldLogToSentry) {
      Sentry.captureException(exception);
    } else {
      // eslint-disable-next-line no-console
      console.error(exception);
    }
  }
}
