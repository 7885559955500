type Mode = 'prod' | 'qa' | 'dev';

export const mode: Mode = (import.meta.env.GATSBY_RUN_MODE?.trim() || 'prod') as Mode;

const commonEnv = {
  SENTRY_DSN: import.meta.env.GATSBY_SENTRY_DSN,
  SENTRY_ENVIRONMENT: mode,
  CURRENT_COMMIT: import.meta.env.GATSBY_CURRENT_COMMIT,
  LOGTO_SENTRY: import.meta.env.GATSBY_LOGTO_SENTRY,
  HIDE_APP: import.meta.env.NODE_ENV !== 'test',
  APP_BUILD_NUMBER: import.meta.env.GATSBY_APP_BUILD_NUMBER,
};

export const envs = {
  prod: {
    ...commonEnv,
    BASE_API_NEW: import.meta.env.GATSBY_PROD_BASE_API_NEW?.trim(),
    BASE_EXPORT_API: import.meta.env.GATSBY_PROD_BASE_EXPORT_API?.trim(),
    BASE_CONTENT_API: import.meta.env.GATSBY_PROD_BASE_CONTENT_API?.trim(),
    TWILIO_URL: import.meta.env.GATSBY_PROD_TWILIO_URL?.trim(),
    GTM_ID: import.meta.env.GATSBY_GTAG_PROD?.trim(),
    COGNITO_USER_POOL_ID: import.meta.env.GATSBY_PROD_AWS_USER_POOL_ID?.trim(),
    COGNITO_USER_POOL_WEB_CLIENT_ID:
      import.meta.env.GATSBY_PROD_AWS_USER_POOL_WEB_CLIENT_ID?.trim(),
  },
  dev: {
    ...commonEnv,
    BASE_API_NEW: import.meta.env.GATSBY_DEV_BASE_API_NEW?.trim(),
    BASE_EXPORT_API: import.meta.env.GATSBY_DEV_BASE_EXPORT_API?.trim(),
    BASE_CONTENT_API: import.meta.env.GATSBY_DEV_BASE_CONTENT_API?.trim(),
    TWILIO_URL: import.meta.env.GATSBY_DEV_TWILIO_URL?.trim(),
    GTM_ID: import.meta.env.GATSBY_GTAG_QA?.trim(), // DEV is using QA ID for now
    COGNITO_USER_POOL_ID: import.meta.env.GATSBY_DEV_AWS_USER_POOL_ID?.trim(),
    COGNITO_USER_POOL_WEB_CLIENT_ID: import.meta.env.GATSBY_DEV_AWS_USER_POOL_WEB_CLIENT_ID?.trim(),
  },
  qa: {
    ...commonEnv,
    BASE_API_NEW: import.meta.env.GATSBY_QA_BASE_API_NEW?.trim(),
    BASE_EXPORT_API: import.meta.env.GATSBY_QA_BASE_EXPORT_API?.trim(),
    BASE_CONTENT_API: import.meta.env.GATSBY_QA_BASE_CONTENT_API?.trim(),
    TWILIO_URL: import.meta.env.GATSBY_QA_TWILIO_URL?.trim(),
    GTM_ID: import.meta.env.GATSBY_GTAG_QA?.trim(),
    COGNITO_USER_POOL_ID: import.meta.env.GATSBY_QA_AWS_USER_POOL_ID?.trim(),
    COGNITO_USER_POOL_WEB_CLIENT_ID: import.meta.env.GATSBY_QA_AWS_USER_POOL_WEB_CLIENT_ID?.trim(),
  },
};

if (!envs[mode]) {
  throw new Error('Invalid environment');
}

const currentEnv: typeof envs.prod = envs[mode];

export default currentEnv;
