import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';
import { ClinicSettings } from 'src/domain/Clinic';

import { useCurrentUser } from './useCurrentUser';

export function useUserClinicsSettings() {
  const { user } = useCurrentUser();
  const { data, error, isLoading, mutate } = useSWR(
    user.sessionToken ? 'clinicsFeatures' : null,
    () => {
      return InsightsApi.get<{ settings: ClinicSettings[] }>('/auth/clinics/settings').then(
        ({ settings }) => settings
      );
    },
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  function hasAccessToFeature(feature: string) {
    return data?.some((clinic) => clinic.features?.includes(feature));
  }

  function clinicHasFeature(clinicId: string, feature: string) {
    const clinic = data?.find((clinic) => clinic.ClinicID === clinicId);

    return clinic?.features?.includes(feature);
  }

  return { hasAccessToFeature, clinicHasFeature, error, isLoading, mutate };
}
