import { CloseIcon, InputGroup, SearchIcon, Tooltip } from '@allurion/ui';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedIconButton, TrackedSortByMenu } from 'src/analytics/TrackedUI';
import { exportClinicData } from 'src/api/ExportApi';
import { DownloadDataBtn, useDownloadData } from 'src/components/DownloadDataBtn';
import { Pagination } from 'src/components/Pagination';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { ActivePatientAlt } from 'src/domain/patient/Patients';
import { isRiskAnalyisEnabled, isExportDataEnabled } from 'src/domain/settings';
import { isAdmin } from 'src/domain/User';
import { useClinicActivePatients } from 'src/hooks/useClinicActivePatients';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useDebounceCallback } from 'src/hooks/useDebounceCallback';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import { useTableSort } from 'src/ui/useTableSort';

import messages from './active-patients-table.messages';
import { ActivePatientsCards } from './ActivePatientsCards';
import { ActivePatientsTable } from './ActivePatientsTable';
import { ClinicPatientsEmptyState } from './ClinicPatientsEmptyState';

import styles from './ClinicActivePatients.module.scss';

type Props = {
  clinicId: string;
};

export function ClinicActivePatients({ clinicId }: Props) {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const { settings } = useClinicSettings(clinicId);
  const { isLoading, activePatients } = useClinicActivePatients(clinicId);
  const exportData = async () => exportClinicData(clinicId, navigator.language);
  const { onExportData, isExportingData } = useDownloadData({ exportData });
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [width] = useThrottledWindowSize();

  const isMobile = width < breakpoints.mobile;

  const filteredPatients = useMemo(
    () =>
      activePatients.filter(
        (patient) =>
          patient.name?.toLowerCase().includes(search?.toLowerCase() ?? '') ||
          patient.lastname?.toLowerCase().includes(search?.toLowerCase() ?? '')
      ),
    [activePatients, search]
  );

  const {
    sortedData: sortedPatients,
    sortBy,
    setSortBy,
  } = useTableSort<ActivePatientAlt>(filteredPatients, '-latest_treatment_start_date', {
    perc_weight_loss_since_placement: 'number',
    weight_loss_since_placement: 'number',
    current_weight: 'number',
    age: 'number',
    at_risk_score: 'number',
    current_bmi: 'number',
  });

  const [page, setPage] = useState(1);
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const paginatedPatients = sortedPatients.slice((page - 1) * pageSize, page * pageSize);
  const lastPage = Math.ceil(sortedPatients.length / pageSize);

  const atRiskEnabled = isRiskAnalyisEnabled(settings);

  const { user } = useCurrentUser();

  const noAvailablePatients = activePatients.length === 0 && !isLoading;
  const emptySearch = search.length > 0 && filteredPatients.length === 0;
  const showEmptyState = noAvailablePatients || emptySearch;

  const hideDownloadDataBtnOnMobile = isMobile && showSearchInput;
  const showDownloadDataBtn =
    isAdmin(user) &&
    isExportDataEnabled(settings) &&
    !hideDownloadDataBtnOnMobile &&
    !showEmptyState;

  const debounceSearch = useDebounceCallback(onSearch, 1500);

  return (
    <div className={styles.container}>
      {!noAvailablePatients && (
        <div className={styles.header}>
          <div className={styles.actionIcons}>
            {showSearchInput ? (
              <div className={styles.patientSearchInputContainer}>
                <InputGroup
                  prefix={<SearchIcon />}
                  textInput={{
                    type: 'text',
                    placeholder: intl.formatMessage(messages.searchPlaceholder),
                    onChange: debounceSearch,
                  }}
                />
                <TrackedIconButton
                  icon={<CloseIcon />}
                  variant="icon"
                  onClick={() => {
                    setShowSearchInput(false);
                    setSearch('');
                  }}
                  trackLabel="close-patient-search-input"
                />
              </div>
            ) : (
              <TrackedIconButton
                icon={<SearchIcon />}
                variant="icon"
                size="sm"
                onClick={() => setShowSearchInput(true)}
                trackLabel="open-patient-search-input"
              />
            )}
          </div>

          <div className={styles.actionIcons}>
            <div className={styles.loader}>{isLoading && <InlineLoader size="16px" />}</div>
            {showDownloadDataBtn && (
              <Tooltip
                text={intl.formatMessage({
                  id: 'clinic-patients.export-data-tooltip',
                  defaultMessage: 'Export data',
                })}
                placement="bottom"
              >
                <DownloadDataBtn exportData={onExportData} />
              </Tooltip>
            )}

            {!showEmptyState && (
              <TrackedSortByMenu
                selectedOption={sortBy}
                trackLabel="clinic-patients-sort-by"
                onChange={onSortByUpdate}
                size="sm"
                tooltipText={intl.formatMessage({
                  id: 'clinic-patients.sort-by-tooltip',
                  defaultMessage: 'Sort patients',
                })}
                options={[
                  {
                    value: 'name',
                    label: intl.formatMessage(messages.firstName),
                  },
                  {
                    value: 'at_risk_score',
                    label: intl.formatMessage(messages.atRisk),
                  },
                  {
                    value: 'age',
                    label: intl.formatMessage(messages.age),
                  },
                  {
                    value: 'current_weight',
                    label: intl.formatMessage(messages.currentWeight),
                  },
                  {
                    value: 'current_bmi',
                    label: 'BMI',
                  },
                  {
                    value: 'weight_loss_since_placement',
                    label: intl.formatMessage(messages.weightChange),
                  },
                  {
                    value: 'perc_weight_loss_since_placement',
                    label: 'TBWL',
                  },
                  {
                    value: 'last_weigh_in',
                    label: intl.formatMessage(messages.lastWeighInDate),
                  },
                  {
                    value: 'latest_treatment_start_date',
                    label: intl.formatMessage(messages.DaysSinceBalloon),
                  },
                ]}
              />
            )}
          </div>
        </div>
      )}

      {isExportingData && <Loader isLoading />}

      {showEmptyState ? (
        <ClinicPatientsEmptyState
          noAvailablePatients={noAvailablePatients}
          emptySearch={emptySearch}
          settings={settings}
          user={user}
          clinicId={clinicId}
        />
      ) : (
        <>
          {isMobile ? (
            <ActivePatientsCards
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
            />
          ) : (
            <ActivePatientsTable
              data={paginatedPatients}
              isAtRiskEnabled={atRiskEnabled}
              clinicId={clinicId}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          )}
          <div className={styles.pagination}>
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              defaultPageSize={defaultPageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              pageSizeLabel={intl.formatMessage({
                id: 'patients.page-size-label',
                defaultMessage: 'Patients per page',
              })}
            />
          </div>
        </>
      )}
    </div>
  );

  function onSortByUpdate(value: string) {
    setSortBy(value);
  }

  function onSearch(e: any) {
    setSearch(e.target.value);
    //reset page to 1 when searching
    if (page !== 1) {
      setPage(1);
    }
  }
}
