import Client from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';

import { Logger } from 'src/services/Logger';

import { TWILIO_KEY_TOKEN, fetchTwilioToken, getTwilioToken } from './TwilioApi';

const SHOW_LOGS = false;

class TwilioConversationsService {
  client: Client | null = null;
  identity = '';
  subscriptions: Function[] = [];
  hasConnectionError = false;
  isConnecting = false;
  private token: string | null = null;
  clientPromise: Promise<Client> | null = null;
  conversations: Conversation[] = [];

  private async connect(identity: string) {
    if (this.isConnecting) {
      await this.waitForClientConnection();
    }

    if (this.client) {
      //if identity is the same we can just return the client
      if (this.client.connectionState === 'connected') {
        return this.client;
      }

      //if identity changed we need to disconnect and reconnect
      if (identity !== this.identity) {
        await this.disconnect();
      }
    }

    this.identity = identity;
    this.isConnecting = true;
    this.log('connecting', identity);
    const token = await this.getToken();

    this.log('token', token);

    this.client = await Client.create(token).catch((err) => {
      this.hasConnectionError = true;
      Logger.captureException(err);
      localStorage.removeItem(TWILIO_KEY_TOKEN);
      throw err;
    });
    this.conversations = await this.getSubscribedConversations(identity);
    this.log('connected');
    this.isConnecting = false;

    this.client.on('tokenAboutToExpire', this.onRenewToken);
    this.client.on('tokenExpired', this.onRenewToken);
    this.client.on('messageAdded', (payload: any) =>
      this.subscriptions.forEach((fn) => fn(payload))
    );

    return this.client;
  }

  private async waitForClientConnection() {
    return new Promise((resolve, reject) => {
      let maxAttempts = 10;
      const interval = setInterval(() => {
        if (this.client?.connectionState === 'connected') {
          clearInterval(interval);
          resolve(true);
        }

        maxAttempts--;

        if (maxAttempts === 0) {
          clearInterval(interval);
          reject(new Error('Client connection timed out'));
        }
      }, 1000);
    });
  }

  private async getToken() {
    if (!this.token) {
      this.token = await getTwilioToken(this.identity);
    }

    if (!this.token) {
      throw new Error('Twilio token not found');
    }

    return this.token;
  }

  private async onRenewToken() {
    localStorage.removeItem(TWILIO_KEY_TOKEN);
    const renewed = await getTwilioToken(this.identity);

    this.client?.updateToken(renewed);
  }

  disconnect() {
    this.log('disconnecting');
    this.client?.shutdown();
    this.identity = '';
  }

  async getSubscribedConversations(identity: string) {
    const client = await this.connect(identity);

    const getNextPage = async (
      paginator: any,
      pageConversations: Conversation[]
    ): Promise<Conversation[]> => {
      if (paginator.hasNextPage) {
        const nextPageConversations = await paginator.nextPage();
        const combinedConversations = pageConversations.concat(nextPageConversations.items);

        return getNextPage(nextPageConversations, combinedConversations);
      }

      return pageConversations;
    };

    const result = await client.getSubscribedConversations();
    const pageConversations = result.items;

    return getNextPage(result, pageConversations);
  }

  async subscribe(identity: string, fn: Function) {
    await this.connect(identity);
    this.subscriptions.push(fn);
  }

  private async getConversationByUniqueName(identity: string, patientIdentity: string) {
    const client = await this.connect(identity);

    return client.getConversationByUniqueName(patientIdentity).catch(() => {
      return null;
    });
  }

  async waitForConversation(identity: string, patientIdentity: string): Promise<Conversation> {
    const client = await this.connect(identity);

    return new Promise((resolve, reject) => {
      let maxAttempts = 10;
      const interval = setInterval(async () => {
        const conversation = await client.getConversationByUniqueName(patientIdentity);

        if (conversation) {
          clearInterval(interval);
          resolve(conversation);
        }

        maxAttempts--;

        if (maxAttempts === 0) {
          clearInterval(interval);
          reject(new Error('Conversation not found'));
        }
      }, 1000);
    });
  }

  private async invitePatient({
    providerName,
    patientIdentity,
    identity,
  }: {
    identity: string;
    patientIdentity: string;
    providerName: string;
  }) {
    this.log('invitePatient', identity, patientIdentity, providerName);
    const inviteToken = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(inviteToken);

    let conversation = await client.getConversationByUniqueName(patientIdentity).catch((e) => {
      Logger.captureException(e);
    });

    if (conversation) {
      this.log('Conversation found, adding participant', identity, patientIdentity, providerName);
      // In case of error lets consider that is no participants to avoid crashes
      const convParticipants = await conversation.getParticipants().catch((e) => {
        Logger.captureException(e);

        return [];
      });
      const filtered = convParticipants.filter((participant) => participant.identity === identity);

      if (!filtered?.length && identity) {
        await conversation.add(identity, { name: providerName });
      }
    } else {
      this.log('Conversation not found, creating new one', identity, patientIdentity, providerName);
      conversation = await client.createConversation({
        uniqueName: patientIdentity,
        friendlyName: patientIdentity,
      });
      await conversation.add(identity, { name: providerName });
      await conversation.add(patientIdentity);
    }

    await client.shutdown();

    return conversation;
  }

  private async updatePatientAttributes({
    patientIdentity,
    attributes,
  }: {
    patientIdentity: string;
    attributes: Record<string, string>;
  }) {
    const token = await fetchTwilioToken(patientIdentity);

    const client = await Client.create(token);

    const conv = await client.getConversationByUniqueName(patientIdentity);

    await conv.updateAttributes(attributes);

    await client.shutdown();
  }

  async setupConversation({
    identity,
    patientIdentity,
    patientId,
    providerName,
  }: {
    identity: string;
    patientIdentity: string;
    patientId: string;
    providerName: string;
  }) {
    this.log(
      '[setupConversation] Checking conversation',
      identity,
      patientIdentity,
      patientId,
      providerName
    );
    let conversation = await this.getConversationByUniqueName(identity, patientIdentity);

    if (!conversation) {
      this.log(
        '[setupConversation] Conversation not found, inviting patient',
        identity,
        patientIdentity,
        providerName
      );
      conversation = await this.invitePatient({ identity, patientIdentity, providerName });
    }
    {
      this.log('[setupConversation] Conversation found', identity, patientIdentity);
    }

    const attributes = await conversation.getAttributes();

    if (!attributes?.patientId) {
      await this.updatePatientAttributes({
        patientIdentity,
        attributes: {
          patientId,
        },
      });
    }
  }

  log(...messages: string[]) {
    if (SHOW_LOGS) {
      // eslint-disable-next-line no-console
      console.log(`[twilio]: ${messages.join(' ')}`);
    }
  }
}

export default new TwilioConversationsService();
