export function CameraOffIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5054_177)">
        <path
          d="M6.00008 2.66675H11.3334V8.00008V13.3334H10.5H0.666748V4"
          stroke="currentColor"
          strokeWidth="1.1"
        />
        <path d="M15 11L15 5L12 8L15 11Z" stroke="currentColor" />
        <path
          d="M0.666748 0.666748L15.3334 15.3334"
          stroke="currentColor"
          strokeWidth="1.1"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5054_177">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
