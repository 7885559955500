import { useEffect } from 'react';

import { PatientProfile } from 'src/domain/patient/Patients';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

import TwilioConversationsService from './TwilioConversationsService';

export function useSetupTwilioConversation(patient?: PatientProfile) {
  const { user } = useCurrentUser();
  const identity = user.username;

  const patientId = patient?.uid;
  const patientUsername = patient?.userid;
  const providerName = `${user?.firstName} ${user?.lastName}`;

  useEffect(() => {
    const setupConversation = async () => {
      if (!patientUsername || !identity || !patientId) {
        return;
      }

      await TwilioConversationsService.setupConversation({
        identity,
        patientIdentity: patientUsername,
        providerName,
        patientId: patientId.toString(),
      });
    };

    setupConversation();
  }, [identity, patientId, patientUsername, providerName]);
}
