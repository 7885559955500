import {
  NoteIcon,
  PageHeader,
  Tabs,
  Tooltip,
  TrashCanIcon,
  useConfirmationDialog,
  VideoIcon,
} from '@allurion/ui';
import { isNull } from '@allurion/utils';
import { Suspense, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedIconButton } from 'src/analytics/TrackedUI';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { Seo } from 'src/components/Seo';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { Loader } from 'src/components/ui/Loader';
import { hasAllurionBallonTreatment } from 'src/domain/patient/treatment';
import {
  isMessagesEnabled,
  isNotesEnabled,
  isVideoEnabled,
  isLibraryContentEnabled,
  isDailyActionsEnabled,
} from 'src/domain/settings';
import { cmToHeightInches } from 'src/helpers/convertions';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { usePatientOverview } from 'src/hooks/usePatientOverview';
import { usePatientProfile, deletePatient } from 'src/hooks/usePatientProfile';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import globalMessages from 'src/messages/global.messages';
import { useSetupTwilioConversation } from 'src/twilio/useSetupTwilioConversation';
import {
  KEY_CHAT,
  KEY_OVERVIEW,
  KEY_SCALE,
  KEY_ACTIVITY,
  PATIENT_PAGE_VALID_TABS,
  KEY_LIBRARY,
  KEY_DAILYACTIONS,
} from 'src/utils/constants';

import { PatientActivities } from './Activities/PatientActivities';
import { PatientChat } from './Chat/PatientChat';
import { PatientContentLibrary } from './ContentLibrary/PatientContentLibrary';
import { PatientDailyActions } from './DailyActions/PatientDailyActions';
import { PatientOverview } from './Overview/PatientOverview';
import messageIntl from './patient-page.messages';
import { PatientScaleData } from './PatientScaleData/PatientScaleData';
import { Sidebar } from './Sidebar/Sidebar';

import styles from './PatientPage.module.scss';

export default function PatientPage() {
  const { patientId } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const { user } = useCurrentUser();
  const { toAnchor, toClinicPage } = useAppNavigate();
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();

  const { patient } = usePatientProfile(patientId);
  const patientUsername = patient?.userid;
  const clinicId = patient?.hospitalid.toString();

  const { settings } = useClinicSettings(clinicId);
  const { reload: reloadOverviewData } = usePatientOverview(patientId);
  const { treatments } = usePatientTreatments(patientId);
  const { trackClick } = useTrackEvent();

  useSetupTwilioConversation(patient);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activeButton, setActiveButton] = useState('');

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const currTab = useMemo(() => {
    const selectedTab = PATIENT_PAGE_VALID_TABS.find((key) => location.hash.includes(key));

    return selectedTab || 'overview';
  }, [location]);

  const reloadPatientData = () => {
    reloadOverviewData();
  };

  const changeTab = (key: string) => {
    toAnchor(key);
  };

  if (!patientId || !clinicId) {
    return null;
  }

  const trackLibraryGoogleAnalytics = (key: string) => {
    // NOTE: location.hash is the hash prior to the click event.
    // i.e., if you are in the video tab, and click on the library tab,
    // then location.hash.slice(1) === 'video'
    if (location.hash?.slice(1) !== key) {
      if (key === KEY_LIBRARY) {
        trackClick(null, {
          label: 'patient-library-tab',
          patientId,
        });
      }
    }
  };

  const trackDailyActionsGoogleAnalytics = (key: string) => {
    if (location.hash?.slice(1) !== key) {
      if (key === KEY_DAILYACTIONS) {
        trackClick(null, {
          label: 'patient-daily-actions-tab',
          patientId,
        });
      }
    }
  };

  const headerSubtitlePatientInfo = () => {
    const { age, height } = patient || {};

    let patientHeight = '';
    const patientAge = age ? `${age} ${intl.formatMessage(messageIntl.patientAge)},` : '';

    if (height) {
      patientHeight =
        patient && user.unitsPreference === 'kg' ? `${height} cm` : cmToHeightInches(height);
    }

    return `${patientAge} ${patientHeight}`;
  };

  const isPatientAvailable = patient?.account_status !== 'UNCONFIRMED' && !isNull(patient?.uuid);

  const handleIconButtonClick = (buttonName: string) => {
    if (activeButton === buttonName) {
      setActiveButton('');
      setIsSidebarOpen(false);

      return;
    }

    setActiveButton(buttonName);
    setIsSidebarOpen(true);
  };

  const handleDeletePatient = async () => {
    if (!patientId) {
      return;
    }

    const shouldDeletePatient = await askConfirmationPromise({
      title: intl.formatMessage({
        id: 'patient-profile.deletePatient',
        defaultMessage: 'Are you sure you want to delete this patient from all clinics?',
      }),
      confirmText: intl.formatMessage(globalMessages.delete),
      cancelText: intl.formatMessage(globalMessages.cancel),
      variant: 'danger',
    });

    if (!shouldDeletePatient) {
      return;
    }
    setIsLoading(true);
    await deletePatient(patientId);
    toClinicPage(clinicId);
  };

  const tabsData = [
    {
      id: KEY_OVERVIEW,
      label: intl.formatMessage(messageIntl.overview),
      content: (
        <PatientOverview patientId={patientId} clinicId={clinicId} reloadData={reloadPatientData} />
      ),
    },
    {
      id: KEY_SCALE,
      label: intl.formatMessage(messageIntl.weight),
      content: <PatientScaleData patientId={patientId} reloadData={reloadPatientData} />,
    },
    {
      id: KEY_ACTIVITY,
      label: intl.formatMessage(messageIntl.activity),
      content: <PatientActivities patientId={patientId} />,
    },
    {
      id: KEY_CHAT,
      hide: !isMessagesEnabled(settings),
      label: intl.formatMessage({ id: messageIntl.chat.id }),
      content: (
        <PatientChat
          patient={patient}
          patientIdentity={patientUsername ?? ''}
          isPatientAvailable={isPatientAvailable}
        />
      ),
    },
    {
      id: KEY_LIBRARY,
      hide: !(isLibraryContentEnabled(settings) && hasAllurionBallonTreatment(treatments)),
      label: intl.formatMessage({ id: 'tab-menu.content-library', defaultMessage: 'Library' }),
      content: <PatientContentLibrary patientId={patientId} />,
      callback: trackLibraryGoogleAnalytics,
    },
    {
      id: KEY_DAILYACTIONS,
      hide: !isDailyActionsEnabled(settings),
      label: intl.formatMessage({
        id: messageIntl.dailyActions.id,
        defaultMessage: 'Daily Actions',
      }),
      content: <PatientDailyActions patientId={patientId} />,
      callback: trackDailyActionsGoogleAnalytics,
    },
  ].filter((tab) => !tab.hide);

  return (
    <div className={styles.wrapper} key={patientId}>
      <ConfirmationDialog />
      <div className={styles.container}>
        <Seo title="Patient" />
        <div className={styles.pageHeader}>
          <PageHeader
            title={`${patient?.name} ${patient?.lastname}`}
            subtitle={headerSubtitlePatientInfo()}
            onNavButtonClick={() => toClinicPage(clinicId)}
            hasBorder={false}
          />
          <div className={styles.pageHeader__content}>
            <div className={styles.pageHeader__content__tabs}>
              <Tabs
                variant="primary"
                direction="row"
                tabs={tabsData}
                onTabChange={(tab) => changeTab(tab.id)}
                selectedTabId={currTab}
              />
            </div>
            <div className={styles.pageHeader__content__actions}>
              {isVideoEnabled(settings) && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'patient-page.video-sidepanel-tooltip',
                    defaultMessage: 'Video',
                  })}
                  placement="bottom"
                >
                  <TrackedIconButton
                    size="sm"
                    variant="secondary"
                    icon={<VideoIcon />}
                    onClick={() => handleIconButtonClick('video')}
                    active={activeButton === 'video'}
                    trackLabel="open-video-calls-panel"
                  />
                </Tooltip>
              )}
              {isNotesEnabled(settings) && (
                <Tooltip
                  text={intl.formatMessage({
                    id: 'patient-page.notes-sidepanel-tooltip',
                    defaultMessage: 'Notes',
                  })}
                  placement="bottom"
                >
                  <TrackedIconButton
                    size="sm"
                    variant="secondary"
                    icon={<NoteIcon />}
                    onClick={() => handleIconButtonClick('notes')}
                    active={activeButton === 'notes'}
                    trackLabel="open-patient-notes-panel"
                  />
                </Tooltip>
              )}
              <Tooltip
                text={intl.formatMessage({
                  id: 'patient-page.delete-patient-tooltip',
                  defaultMessage: 'Delete patient',
                })}
                placement="bottom"
              >
                <TrackedIconButton
                  size="sm"
                  variant="secondary"
                  icon={<TrashCanIcon />}
                  onClick={handleDeletePatient}
                  trackLabel="delete-patient"
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={styles.innerContainer}>
          <ErrorBoundary>
            {tabsData.map(({ id, content }) => (
              <div className={currTab === id ? styles.content : styles.content__hide} key={id}>
                <Suspense fallback={<InlineLoader />}>{content}</Suspense>
              </div>
            ))}
          </ErrorBoundary>
        </div>
        <Loader isLoading={isLoading} />
      </div>
      <Sidebar
        isOpen={isSidebarOpen}
        setActiveButton={setActiveButton}
        setIsOpen={setIsSidebarOpen}
        selectedButton={activeButton}
      />
    </div>
  );
}
