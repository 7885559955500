import { Message } from '@twilio/conversations/lib/message';
import { useState, useEffect } from 'react';

import { ParsedMessageNotification } from 'src/domain/Conversations';

import TwilioConversationsService from './TwilioConversationsService';

export function useTwilioIncommingMessages(identity?: string) {
  const [incomingMessage, setIncomingMessage] = useState<ParsedMessageNotification | undefined>(
    undefined
  );

  useEffect(() => {
    if (!identity) {
      return;
    }

    TwilioConversationsService.subscribe(identity, (message: Message) => {
      const { body, conversation, author, attributes } = message || {};

      const { name: title } = attributes as any;
      const { patientId } = conversation?.attributes as any;
      // This will work as long as the created by of the conversations is the patient id?
      const isPatientMessage = author === conversation.createdBy;

      const parsedMessage: ParsedMessageNotification = {
        title,
        body,
        author,
        patientId,
        isPatientMessage,
      };

      setIncomingMessage(parsedMessage);
    });
  }, [identity]);

  const clearIncomingMessage = () => {
    setIncomingMessage(undefined);
  };

  return {
    clearIncomingMessage,
    incomingMessage,
  };
}
