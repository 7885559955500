import {
  ClinicsNavbarDesktop,
  TabProps,
  FolderIcon,
  GroupIcon,
  SettingsIcon,
  AnalyticsIcon,
  BurgerIcon,
  CloseIcon,
  UserIcon,
} from '@allurion/ui';
import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { Clinic } from 'src/domain/Clinic';
import { isAdmin } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import * as appRoutes from 'src/utils/appRoutes';
import currentEnv from 'src/utils/env';

import styles from './ClinicSidebar.module.scss';

type Props = {
  isMenuCollapsed: boolean;
  visibleOnMobile: boolean;
  setVisibleOnMobile: (visible: boolean) => void;
  setIsMenuCollapsed: (collapsed: boolean) => void;
  clinics: Clinic[];
  clinicHasFeature: (clinicId: string, feature: string) => boolean;
  clinicId: string;
};

export function ClinicSidebar({
  isMenuCollapsed,
  setIsMenuCollapsed,
  visibleOnMobile,
  setVisibleOnMobile,
  clinics,
  clinicHasFeature,
  clinicId,
}: Props) {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedTab, setSelectedTab] = useState('0');
  const { navigateAndScrollToTop } = useAppNavigate();
  const { user } = useCurrentUser();

  const [width] = useThrottledWindowSize();
  const isMobile = width < breakpoints.mobile;

  const CLINIC_NAME_MAX_LENGTH = 37;

  const menuClinics = clinics.map((clinic) => {
    const clinicTabs = [
      {
        id: 'patients-tab',
        label: intl.formatMessage({
          id: 'navmenu.patients',
          defaultMessage: 'Patients',
        }),
        icon: <UserIcon />,
        link: appRoutes.toClinicPatientsPage(clinic.ID),
        tooltip: {
          text: intl.formatMessage({
            id: 'navmenu.patients',
            defaultMessage: 'Patients',
          }),
          position: 'right',
        },
      },
      {
        id: 'organize-patients-tab',
        label: intl.formatMessage({
          id: 'navmenu.organize-patients',
          defaultMessage: 'Organize Patients',
        }),
        icon: <FolderIcon />,
        link: appRoutes.managePatientsPage(clinic.ID),
        hide: !clinicHasFeature(clinic.ID, 'patient_grouping') || !isAdmin(user),
        tooltip: {
          text: intl.formatMessage({
            id: 'navmenu.organize-patients',
            defaultMessage: 'Organize Patients',
          }),
          position: 'right',
        },
      },
      {
        id: 'analytics-tab',
        label: intl.formatMessage({
          id: 'navmenu.analytics',
          defaultMessage: 'Analytics',
        }),
        icon: <AnalyticsIcon />,
        link: appRoutes.toClinicAnalytics(clinic.ID),
        tooltip: {
          text: intl.formatMessage({
            id: 'navmenu.analytics',
            defaultMessage: 'Analytics',
          }),
          position: 'right',
        },
      },
      {
        id: 'clinic-members-tab',
        label: intl.formatMessage({
          id: 'navmenu.clinic-members',
          defaultMessage: 'Clinic members',
        }),
        icon: <GroupIcon />,
        link: appRoutes.toClinicProviders(clinic.ID),
        hide: !isAdmin(user),
        tooltip: {
          text: intl.formatMessage({
            id: 'navmenu.clinic-members',
            defaultMessage: 'Clinic members',
          }),
          position: 'right',
        },
      },
      {
        id: 'clinic-settings-tab',
        label: intl.formatMessage({
          id: 'navmenu.settings',
          defaultMessage: 'Settings',
        }),
        icon: <SettingsIcon />,
        link: appRoutes.toClinicSettings(clinic.ID),
        hide: !isAdmin(user),
        tooltip: {
          text: intl.formatMessage({
            id: 'navmenu.settings',
            defaultMessage: 'Settings',
          }),
          position: 'right',
        },
      },
    ];

    const menuClinic = {
      id: clinic.ID,
      name:
        clinic.post_title.length < CLINIC_NAME_MAX_LENGTH
          ? clinic.post_title
          : `${clinic.post_title.substring(0, CLINIC_NAME_MAX_LENGTH)}...`,
      tabs: clinicTabs.filter((tab) => !tab.hide),
    };

    return menuClinic;
  });

  const onClinicChange = (clinicId: string) => {
    navigateAndScrollToTop(appRoutes.toClinicPage(clinicId));
    sessionStorage.setItem('clinicId', clinicId);
    setVisibleOnMobile(false);
  };

  const onTabChange = (tab: TabProps) => {
    if (!tab.link || !tab.id) return;
    setSelectedTab(tab.id);
    setVisibleOnMobile(false);
    navigateAndScrollToTop(tab.link);
  };

  const footerInfo = {
    build: currentEnv?.APP_BUILD_NUMBER,
    privacyLink: intl.formatMessage({
      id: 'footer.privacy-policy-html',
      defaultMessage: 'http://templates.insights.allurion.com/privacy_policy/privacy-policy.html',
    }),
    privacyText: intl.formatMessage({
      id: 'footer.privacy-policy',
      defaultMessage: 'Privacy Policy',
    }),
    email: 'help@allurion.com',
    subject: 'Insights Assistance',
  };

  const handleCloseMenu = (e: any) => {
    //should close when clicking outside
    if (containerRef.current === e.target) {
      setVisibleOnMobile(false);
      e.stopPropagation();
    }
  };

  const containerClass = `
    ${styles.container}
    ${isMobile ? styles.mobile : ''}
    ${visibleOnMobile ? `${styles.visible} mobile-menu-visible` : ''}
  `;

  return (
    <div className={containerClass} ref={containerRef} onClick={handleCloseMenu}>
      <ClinicsNavbarDesktop
        clinics={menuClinics}
        onClinicChange={onClinicChange}
        onTabChange={onTabChange}
        selectedClinic={clinicId}
        selectedTab={selectedTab}
        isCollapsed={isMenuCollapsed}
        footerInfo={footerInfo}
        menu={{
          label: intl.formatMessage({
            id: 'navmenu.clinics',
            defaultMessage: 'Clinics',
          }),
          icon: visibleOnMobile && isMobile ? <CloseIcon /> : <BurgerIcon />,
          onMenuClick:
            visibleOnMobile && isMobile
              ? () => setVisibleOnMobile(false)
              : () => setIsMenuCollapsed(!isMenuCollapsed),
        }}
        tabTemplate={(tab, classNames) =>
          tab.link ? (
            <NavLink
              to={tab.link}
              className={({ isActive }) => classNames(isActive).join(' ')}
              onClick={() => setVisibleOnMobile(false)}
            >
              {tab.icon}
              {tab.label}
            </NavLink>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
}
