import { useDialog } from '@allurion/ui';
import { FIVE_MINUTES_IN_MS, THIRTY_MINUTES_IN_MS } from '@allurion/utils';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';

import { getUserSession } from 'src/helpers/userSession';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useInterval } from 'src/hooks/useInterval';
import globalMessages from 'src/messages/global.messages';

// Make sure this popup gives them at least ??? seconds
const TIME_REMAINING_SECONDS = 60;

export function SessionTimeout() {
  const [timeRemaining, setTimeRemaining] = useState<number>(TIME_REMAINING_SECONDS);
  const intl = useIntl();
  const { user, logout } = useCurrentUser();
  const { toLoginPage } = useAppNavigate();

  useInterval(
    async () => {
      try {
        // Keep alive session (refresh token if needed) every 5 minutes
        await getUserSession();
      } catch (e) {
        toLoginPage();
      }
    },
    { delay: FIVE_MINUTES_IN_MS, triggerOnSchedule: true }
  );

  const { Dialog, openDialog } = useDialog({
    content: () => (
      <>
        {intl.formatMessage({
          id: 'session-timeout.warning-message',
          defaultMessage: `Your session is about to expire. Would you like to continue?`,
        })}
      </>
    ),
  });

  const handleOnIdle = () => {
    // TODO: delete `if` condition, uncomment the code under it
    if (user.sessionToken) {
      logout({ showSessionTimeoutWarning: true });
    }
    // const ignoreRoutes = !(window.location.hash.includes(KEY_VIDEO) || window.location.hash.includes(KEY_CHAT));
    // if (user.sessionToken && ignoreRoutes) {
    //   setIsModalOpen(true);
    // }
  };

  const handleOnConfirm = () => {
    setTimeRemaining(TIME_REMAINING_SECONDS);
    reset();
  };

  const { reset } = useIdleTimer({
    timeout: THIRTY_MINUTES_IN_MS,
    onIdle: handleOnIdle,
    debounce: 500,
    startOnMount: true,
    crossTab: true,
  });

  useEffect(() => {
    // When the session actually does time out, return them to the login screen
    if (timeRemaining < 1) {
      setTimeRemaining(TIME_REMAINING_SECONDS);
      const timeout = setTimeout(() => {
        logout({ showSessionTimeoutWarning: true });
      }, TIME_REMAINING_SECONDS);

      openDialog({
        title: 'Session Expired',
        onConfirm: () => {
          clearTimeout(timeout);
          handleOnConfirm();
        },
        confirmText: intl.formatMessage(globalMessages.confirm),
        contentProps: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining]);

  return <Dialog />;
}
