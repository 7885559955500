import { PageHeader } from '@allurion/ui';
import { toDateStr } from '@allurion/utils';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { ProvidersSelector } from 'src/components/ProvidersSelector';
import { AllurionCheckboxes } from 'src/components/ui/AllurionCheckboxes';
import { AllurionDatePicker } from 'src/components/ui/AllurionDatePicker';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { isAllurionBallonTreatment, isValidStartDate } from 'src/domain/patient/treatment';
import { isLibraryContentEnabled, isPatientGroupingEnabled } from 'src/domain/settings';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useArticleCollections } from 'src/hooks/useArticleCollections';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { CloseIcon } from 'src/icons/CloseIcon';
import globalMessages from 'src/messages/global.messages';
import { AddPatientData } from 'src/pages/InvitePatientPage/AddPatient.types';
import treatmentListTranslations from 'src/pages/PatientPage/Overview/PatientTreamentsCard/PatientTreamentsCard.translations';
import { TreatmentSelector } from 'src/pages/PatientPage/Overview/PatientTreamentsCard/TreatmentSelector';

import { Container, InnerContainer } from '../shared-page-elements';

import translations from './AddPatientPage.translations';

type Props = {
  data: AddPatientData;
  next: Function;
  clinicId: string;
};

export function InvitePatientForm({ data, clinicId, next }: Props) {
  const intl = useIntl();
  const { toPreviousPage } = useAppNavigate();

  const { treatments } = useClinicTreatements(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { articleCollections } = useArticleCollections();
  const subsOpts = articleCollections.map(({ title, id }) => ({ label: title, value: id }));

  const offeredTreatments = treatments.map((treatment) => treatment.custom_treatment_id);

  const onSubmit = (values: AddPatientData) => {
    next(values);
  };

  const AddPatientSchema = Yup.object().shape({
    firstName: Yup.string().required(intl.formatMessage(globalMessages.required)),
    lastName: Yup.string().required(intl.formatMessage(globalMessages.required)),
    email: Yup.string().email().required(intl.formatMessage(globalMessages.required)),
    treatmentType: Yup.number()
      .required(intl.formatMessage(globalMessages.required))
      .moreThan(0, intl.formatMessage(globalMessages.required)),
    startDate: Yup.string().required(intl.formatMessage(globalMessages.required)),
    collections: Yup.array(),
    providers: isPatientGroupingEnabled(settings)
      ? Yup.array().min(1, intl.formatMessage(globalMessages.required))
      : Yup.array(),
  });

  return (
    <>
      <PageHeader
        title={intl.formatMessage(translations.title)}
        onNavButtonClick={() => toPreviousPage()}
      />
      <Container>
        <InnerContainer>
          <Formik
            initialValues={data}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={AddPatientSchema}
          >
            {({ handleSubmit, setFieldValue, setErrors, errors, values }) => {
              const onDateSelect = (date: Date) => {
                // Set the time to noon to avoid timezone issues
                date.setUTCHours(11);
                const startDate = toDateStr(date);

                if (startDate && !isValidStartDate(startDate)) {
                  setErrors({
                    ...errors,
                    startDate: intl.formatMessage(treatmentListTranslations.startDateErrorMessage),
                  });

                  return;
                }

                setFieldValue('startDate', startDate);
                setErrors({ ...errors, startDate: undefined });
              };

              const clearError = (e: any) => {
                const {
                  target: { name },
                } = e;

                setErrors({ ...errors, [name]: undefined });
              };

              const shouldRenderSubscriptions =
                isLibraryContentEnabled(settings) &&
                hasAllurionBallonTreatmentSelected(values.treatmentType);

              const handleTreatmentChange = (value: number) => {
                // assign an empty array if the treatment is not an Allurion Ballon
                const collectionsValue = hasAllurionBallonTreatmentSelected(value)
                  ? subsOpts.map((option) => option.value)
                  : [];

                setFieldValue('collections', collectionsValue);
              };

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <FieldWrapper>
                    <AllurionTextField
                      label={intl.formatMessage(translations.firstName)}
                      name="firstName"
                      onFocus={clearError}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <AllurionTextField
                      label={intl.formatMessage(translations.lastName)}
                      name="lastName"
                      onFocus={clearError}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <AllurionTextField
                      label={intl.formatMessage(translations.email)}
                      name="email"
                      onFocus={clearError}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <LabelField>{intl.formatMessage(translations.treatment)}</LabelField>
                    <TreatmentSelector
                      clinicId={clinicId}
                      name="treatmentType"
                      selectTreatmentTypeID={data.treatmentType.toString()}
                      treatmentsOffered={offeredTreatments}
                      onChange={handleTreatmentChange}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <LabelField>{intl.formatMessage(translations.startDate)}</LabelField>
                    <AllurionDatePicker
                      onSelect={onDateSelect}
                      defaultDate={data.startDate}
                      name="startDate"
                      onFocus={clearError}
                      cyErrorLabelSelector="datePickerError"
                    />
                  </FieldWrapper>
                  {shouldRenderSubscriptions && (
                    <SectionWrapper>
                      <FieldWrapper>
                        <Label>
                          {intl.formatMessage({
                            id: 'patient-profile.library-content.manage-subscription',
                            defaultMessage: 'Manage Content Subscription',
                          })}
                        </Label>
                        <AllurionCheckboxes
                          name="collections"
                          options={subsOpts}
                          preSelected={values.collections}
                          onChecked={(collections: string[]) =>
                            setFieldValue('collections', collections)
                          }
                        />
                      </FieldWrapper>
                    </SectionWrapper>
                  )}
                  {isPatientGroupingEnabled(settings) && (
                    <SectionWrapper>
                      <FieldWrapper>
                        <LabelWithDescription>
                          {intl.formatMessage(translations.providerAccess)}
                        </LabelWithDescription>
                        <LabelDescription>
                          ({intl.formatMessage(translations.providerAccessDescription)})
                        </LabelDescription>
                        <ProvidersSelector
                          label={intl.formatMessage(translations.providerAccessFieldLabel)}
                          clinicId={clinicId}
                          name="providers"
                          onChange={(value) => setFieldValue('providers', value)}
                          selected={values.providers}
                        />
                      </FieldWrapper>
                    </SectionWrapper>
                  )}
                  <ButtonsWrapper>
                    <TrackedButton
                      label={intl.formatMessage(translations.submit)}
                      type="submit"
                      size="sm"
                      trackLabel="submit-invite-patient-form"
                    />

                    <TrackedButton
                      label={intl.formatMessage(translations.cancel)}
                      type="button"
                      variant="outlined"
                      size="sm"
                      leftIcon={document.dir === 'ltr' && <CloseIcon size="20px" />}
                      rightIcon={document.dir === 'rtl' && <CloseIcon size="20px" />}
                      onClick={() => toPreviousPage()}
                      trackLabel="cancel-invite-patient-form"
                    />
                  </ButtonsWrapper>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerContainer>
      </Container>
    </>
  );

  function hasAllurionBallonTreatmentSelected(treatmentId: number) {
    const selectedTreatment = treatments.find(
      ({ custom_treatment_id }) => custom_treatment_id === treatmentId.toString()
    );

    return isAllurionBallonTreatment(selectedTreatment?.treatment_type_id);
  }
}

const StyledForm = styled(Form)`
  max-width: 450px;
`;

const FieldWrapper = styled.div`
  label {
    line-height: 130%;
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.Primary};
  margin-bottom: 15px;
  font-size: 20px;
  display: inline-block;
  font-family: 'GT-America-Standard-Regular', sans-serif;
`;

const LabelWithDescription = styled(Label)`
  margin-bottom: 0;
`;

const LabelDescription = styled.p`
  font-size: 16px;
  margin: 5px 0;
`;

const LabelField = styled(Label)`
  font-family: 'GT-America-Standard-Regular', sans-serif;
  padding-top: 15px;
`;

const SectionWrapper = styled.div`
  margin-top: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 16px;
`;
