import { ClinicSettings } from 'src/domain/Clinic';

export enum ClinicSettingsFeatures {
  ClinicLedOnboarding = 'add_patient',
  RiskAnalysis = 'at_risk',
  LibraryContent = 'library',
  ExportData = 'download_data',
  Messages = 'messages',
  Notes = 'notes',
  Video = 'video',
  DailyActions = 'daily_actions',
  ManualWeightEntry = 'manual_weight_entry',
  PatientGrouping = 'patient_grouping',
  EmailNotifications = 'email_notifications',
}

export function getDefaultClinicSettings(clinicId: string | undefined): ClinicSettings {
  return {
    weightUnit: 'kg',
    features: [ClinicSettingsFeatures.Notes],
    ClinicID: clinicId || '',
    treatmentsOffered: [],
  };
}

export function isDailyActionsEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.DailyActions);
}

export function isMessagesEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.Messages);
}

export function isVideoEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.Video);
}

export function isNotesEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.Notes);
}

export function isClinicLedOnboardingEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.ClinicLedOnboarding);
}

export function isRiskAnalyisEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.RiskAnalysis);
}

export function isLibraryContentEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.LibraryContent);
}

export function isManualWeightEntryEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.ManualWeightEntry);
}

export function isExportDataEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.ExportData);
}

export function isPatientGroupingEnabled(settings?: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.PatientGrouping);
}

export function isEmailNotificationsEnable(settings: ClinicSettings) {
  return !!settings?.features?.includes(ClinicSettingsFeatures.EmailNotifications);
}
