import { isString } from '@allurion/utils';
import { useCallback } from 'react';
import useSWR from 'swr';

import { InsightsApi } from 'src/api/Api';
import { ClinicSettings } from 'src/domain/Clinic';
import { getDefaultClinicSettings } from 'src/domain/settings';

export const CLINIC_SETTINGS = (clinicId: string) => `/clinics/${clinicId}/settings`;

export function useClinicSettings(clinicId?: string) {
  const { data, error, isValidating, mutate } = useSWR(
    clinicId && ['getClinicSettingsById', clinicId],
    ([, id]: [string, string]) => getClinicSettings(id),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  const updateSettings = useCallback(
    (setting: ClinicSettings) =>
      updateClinicSettings(setting).then(() => {
        mutate();
      }),
    [mutate]
  );

  return {
    settings: data ?? getDefaultClinicSettings(clinicId),
    error,
    isLoading: isValidating,
    updateSettings,
  };
}

function updateClinicSettings({ ClinicID, ...body }: ClinicSettings) {
  return InsightsApi.put<ClinicSettings>(CLINIC_SETTINGS(ClinicID), body);
}

function getClinicSettings(clinicID: string) {
  return (
    InsightsApi.get<ClinicSettings>(CLINIC_SETTINGS(clinicID))
      // FIXME: In case that features returns a string, we convert it into an array.
      // To be removed once backend is fixes this case.
      .then((s) => {
        const features: string[] | undefined = isString(s?.features) ? [s?.features] : s?.features;
        const treatmentsOffered: number[] | undefined =
          typeof s?.treatmentsOffered === 'string' ? [s?.treatmentsOffered] : s?.treatmentsOffered;

        return { ...s, features, treatmentsOffered };
      })
  );
}
