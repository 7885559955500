import { SettingsControls as SettingsControl } from './SettingsControls';
import { SettingsWrapper } from './styles';

type Props = {
  onSave?: Function;
  isCallOver?: boolean;
  isOpen: boolean;
};

export function VideoCallSettings({ onSave = () => {}, isCallOver, isOpen }: Props) {
  const changeDevice = (device: any) => onSave(device);

  return (
    <SettingsWrapper>
      {isOpen && <SettingsControl onChangeDevice={changeDevice} isCallOver={isCallOver} />}
    </SettingsWrapper>
  );
}
