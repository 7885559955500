import useSWR from 'swr';

import { buildUrl, ContentApi } from 'src/api/Api';
import { ArticleCollection } from 'src/domain/patient/Patients';
import { getUserLanguage } from 'src/helpers/locale';

import { useLocale } from './useLocale';

export const ARTICLE_COLLECTIONS = '/collections';

export function useArticleCollections() {
  const { locale } = useLocale();
  const userLocale = getUserLanguage(locale);

  const { data, error, isValidating } = useSWR(
    ['get-article-collections', userLocale],
    () => getAvailableArticleCollections(userLocale).then((res) => res.data),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { isLoading: isValidating, articleCollections: data ?? [], error };
}

export function getAvailableArticleCollections(locale: string) {
  let normalizedLocale = locale;

  if (locale === 'en') {
    normalizedLocale = 'en-US';
  }

  return ContentApi.get<{ data: ArticleCollection[] }>(
    buildUrl(ARTICLE_COLLECTIONS, {
      locale: normalizedLocale,
    })
  );
}
