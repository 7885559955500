import { isError } from '@allurion/utils';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { SelectedDevice } from '../video-call-types';
import messages from '../video-call.messages';

import { CameraSelector } from './CameraSelector';
import { MicrophoneSelector } from './MicrophoneSelector';
import { SpeakerSelector } from './SpeakerSelector';
import { SettingsContainer, WarningNote } from './styles';

type Props = {
  onChangeDevice?: Function;
  isCallOver?: boolean;
};

export function SettingsControls({ onChangeDevice = () => {}, isCallOver }: Props) {
  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>(null);
  const [, /* deviceError */ setDeviceError] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    const getDevices = async () => {
      try {
        const mediaDevices = await navigator?.mediaDevices?.enumerateDevices();

        setDevices(mediaDevices);
      } catch (err: unknown) {
        // eslint-disable-next-line no-alert
        window.alert(isError(err) ? `${err.name}: ${err.message}` : err);
      }
    };

    if (!devices) {
      getDevices();
    }
  }, [devices]);

  const onSelection = (device: SelectedDevice) => {
    onChangeDevice(device);
  };

  const onPermissionError = (e: any) => {
    if (e?.name === 'NotAllowedError') {
      setDeviceError(true);
    }
  };

  const microphones =
    devices?.filter((device: MediaDeviceInfo) => device.kind === 'audioinput') || [];
  const speakers =
    devices?.filter((device: MediaDeviceInfo) => device.kind === 'audiooutput') || [];
  const cameras = devices?.filter((device: MediaDeviceInfo) => device.kind === 'videoinput') || [];

  const message = intl.formatMessage(messages.audioLevel);

  return (
    <SettingsContainer>
      <CameraSelector
        cameras={cameras}
        onSelection={onSelection}
        isCallOver={isCallOver}
        onPermissionError={onPermissionError}
      />
      <SpeakerSelector speakers={speakers} onSelection={onSelection} />
      <MicrophoneSelector
        microphones={microphones}
        onSelection={onSelection}
        isCallOver={isCallOver}
        onPermissionError={onPermissionError}
      />
      <WarningNote>{message}</WarningNote>
    </SettingsContainer>
  );
}
