import { useEffect } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { ParsedMessageNotification } from 'src/domain/Conversations';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLocale } from 'src/hooks/useLocale';
import { fetchPatientData } from 'src/hooks/usePatientProfile';
import { useUserClinics } from 'src/hooks/useUserClinics';
import { CloseIcon } from 'src/icons/CloseIcon';
import { MailOutlineIcon } from 'src/icons/MailOutlineIcon';
import { Logger } from 'src/services/Logger';
import { useTwilioIncommingMessages } from 'src/twilio/useTwilioIncommingMessages';
import { KEY_CHAT } from 'src/utils/constants';

const disabledPaths = ['/create-password'];

const timeout = 20000;
const messageNotificationId = 'message-notification';
const notificationsLimit = 7;

function Content({ title, body }: IMessageNotification) {
  return (
    <Notification>
      <div className="icon">
        <MailOutlineIcon size="24" fill="#00363F" />
      </div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="body">{body}</div>
      </div>
    </Notification>
  );
}

const showToast = ({ title, body, onClick, toastId, onClose }: IShowToast) =>
  toast(<Content title={title} body={body} />, {
    containerId: messageNotificationId,
    onClick,
    toastId,
    onClose,
  });

export function MessageNotification() {
  const { locale } = useLocale();
  const intl = useIntl();
  const { user } = useCurrentUser();
  const { clinics } = useUserClinics();
  const location = useLocation();
  const { toPatientPage } = useAppNavigate();

  const isDisabled = disabledPaths.includes(location.pathname);

  const identity = user.username;
  const { incomingMessage, clearIncomingMessage } = useTwilioIncommingMessages(identity);

  useEffect(() => {
    const showNotification = async (payload: ParsedMessageNotification) => {
      const { author, title, body, patientId, isPatientMessage } = payload || {};
      const onClick = () => {
        if (patientId) {
          toPatientPage(patientId, KEY_CHAT);
        }
      };

      const shouldNotDisplay = identity === author || location.hash.includes(KEY_CHAT);
      let clinicCheck = true;

      // patient data cannot be fetched without a patientId
      if (!patientId) {
        Logger.captureMessage('User received a notification without a patientId');

        const patientData = await fetchPatientData(patientId!);
        const patientClinicId = patientData?.hospitalid;

        if (!clinics.find((clinic) => clinic.ID === patientClinicId.toString())) {
          // eslint-disable-next-line no-console
          console.log(
            `User should not see patient message notification from clinic ${patientClinicId}`
          );
          clinicCheck = false;
        }
      }

      if (!shouldNotDisplay && clinicCheck && isPatientMessage) {
        showToast({
          title: title!,
          // body is null when attachment is sent
          body:
            body ??
            intl.formatMessage({
              id: 'message-notification.sent-attachment',
              defaultMessage: 'Sent an attachment',
            }),
          onClick,
          toastId: author,
          onClose: clearIncomingMessage,
        });
      }
    };

    // There is a change that `incommingMessage` could be truthy, so we use `user.sessionToken` to validate if the user is login
    // there is a redirect bug happening
    // making a api call to getPatient data, but user has no session, and we get 401, and we get a userSession timeout
    if (!isDisabled && incomingMessage && user.sessionToken && identity) {
      showNotification(incomingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMessage, user.sessionToken, identity]);

  const isArabic = locale === 'ar';

  // eslint-disable-next-line react/function-component-definition
  const CloseButton = ({
    closeToast,
  }: {
    closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <button
      className="close-button"
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        closeToast(e);
      }}
    >
      <CloseIcon size="20" color="#77989E" />
    </button>
  );

  if (isDisabled) {
    return null;
  }

  return (
    <StyledToasterContainer
      containerId={messageNotificationId}
      autoClose={timeout}
      rtl={isArabic}
      limit={notificationsLimit}
      closeButton={CloseButton}
      hideProgressBar
      enableMultiContainer
      closeOnClick
    />
  );
}
interface IMessageNotification {
  title: string;
  body?: string;
}

interface IShowToast extends IMessageNotification {
  // onClick?: (event: MouseEvent<Element, MouseEvent>) => void;
  // onClose?: (event: MouseEvent<Element, MouseEvent>) => void;
  onClick?: any;
  onClose?: any;
  toastId?: string;
}

const StyledToasterContainer = styled(ToastContainer)`
  .close-button {
    background: none;
    cursor: pointer;
  }
  .Toastify__toast--default {
    background-color: ${({ theme }) => theme.colors.White};
    color: ${({ theme }) => theme.colors.Primary};
    padding-right: 22px;
    padding-left: 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
`;

const Notification = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin: 0 18px 0 0;
  }
  .content {
    max-width: 260px;
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      box-sizing: content-box;
      -webkit-box-orient: vertical;
      font-family: 'GT-America-Standard-Bold', sans-serif;
      /* font-weight: 700; */
      line-height: 24px;
      vertical-align: top;
    }

    .body {
      font-size: 13px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      box-sizing: content-box;
      -webkit-box-orient: vertical;
      line-height: normal;
    }
  }
`;
