import { SettingsIcon } from '@allurion/ui';
import { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { usePatientProfile } from 'src/hooks/usePatientProfile';
import { TabContentMessage } from 'src/styles/components';
import { useTwilioVideo } from 'src/twilio/useTwilioVideo';

import messageIntlVid from '../VideoCall/video-call.messages';

import { SelectedDevice } from './video-call-types';
import { VideoCallHistory } from './VideoCallHistory';
import { VideoCallLobby } from './VideoCallLobby';
import { VideoCallRoom } from './VideoCallRoom';
import { VideoCallSettings } from './VideoCallSettings/VideoCallSettings';

import styles from './PatientVideoCall.module.scss';

type Props = {
  setIsSidebarOpen?: (isOpen: boolean) => void;
  setActiveButton?: (button: string) => void;
};

export function PatientVideoCall({ setIsSidebarOpen, setActiveButton }: Props) {
  const [device, setDevice] = useState<SelectedDevice | undefined>();
  const intl = useIntl();

  const { patientId } = useParams();

  const { patient } = usePatientProfile(patientId);

  const patientUsername = patient?.userid;

  const isPatientAvailable = patient?.account_status !== 'UNCONFIRMED' && patient?.uuid;

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  const {
    room,
    participants,
    muteAudio,
    setMuteAudio,
    stopVideo,
    setStopVideo,
    isCallOver,
    setIsCallOver,
    setStartCall,
    disconnectVideoCall,
  } = useTwilioVideo({
    patientUsername,
    timeoutCallback: () => window.alert(intl.formatMessage(messageIntlVid.timeout)),
  });

  if (!patient) {
    return null;
  }

  const saveSettings = (selectedDevices: SelectedDevice) => setDevice(selectedDevices);

  if (!isPatientAvailable) {
    return (
      <TabContentMessage>
        {intl.formatMessage({
          id: 'chat-error.notInstalled',
          defaultMessage: 'Patient has not installed Allurion mobile app',
        })}
      </TabContentMessage>
    );
  }

  const handleCloseClick = () => {
    if (!isCallOver) {
      setIsCallOver(true);
    }
    setIsSidebarOpen!(false);
    setActiveButton!('');
  };

  return (
    <>
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'patient-video-panel.title',
          defaultMessage: 'Video calls',
        })}
        navButtonVariant="close"
        onNavButtonClick={() => handleCloseClick()}
        onNavButtonClickTrackLabel="close-video-calls-panel"
        iconButtons={[
          {
            variant: 'icon',
            size: 'sm',
            icon: <SettingsIcon />,
            onClick: () => setIsSettingsOpen(!isSettingsOpen),
            active: isSettingsOpen,
            trackLabel: 'open-video-calls-settings',
          },
        ]}
      />
      <div className={styles.innerContainer}>
        <div className={styles.videoCall}>
          {!room && (
            <VideoCallLobby patientId={patientId!} onStartCall={async () => setStartCall(true)} />
          )}
          <VideoCallSettings
            onSave={saveSettings}
            isCallOver={isCallOver}
            isOpen={isSettingsOpen}
          />
          {room && (
            <VideoCallRoom
              room={room}
              onLogout={disconnectVideoCall}
              participants={participants}
              muteAudio={muteAudio}
              stopVideo={stopVideo}
              setMuteAudio={setMuteAudio}
              setStopVideo={setStopVideo}
              isCallOver={isCallOver}
              setIsCallOver={setIsCallOver}
              device={device}
              patientId={patientId!}
            />
          )}
          <Suspense fallback={<InlineLoader />}>
            <VideoCallHistory patientId={patientId!} />
          </Suspense>
        </div>
      </div>
    </>
  );
}
