import { ClinicsNavbarMobile } from '@allurion/ui';
import { TabProps } from '@allurion/ui';
import { UserIcon, FolderIcon, BurgerIcon, AnalyticsIcon } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, NavLink } from 'react-router-dom';

import { useClinic } from 'src/hooks/useClinic';
import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import { breakpoints } from 'src/styles/theme/breakpoints';
import * as appRoutes from 'src/utils/appRoutes';

import styles from './MobileMenu.module.scss';

type Props = {
  setSidebarVisible: (visible: boolean) => void;
  setIsMenuCollapsed: (collapsed: boolean) => void;
  clinicHasFeature: (clinicId: string, feature: string) => boolean;
  clinicId: string;
};

export function MobileMenu({
  setSidebarVisible,
  setIsMenuCollapsed,
  clinicHasFeature,
  clinicId,
}: Props) {
  const [selectedTab, setSelectedTab] = useState('0');
  const { clinic } = useClinic(clinicId);
  const intl = useIntl();
  const [width] = useThrottledWindowSize();
  const navigate = useNavigate();
  const isMobile = width < breakpoints.mobile;

  if (!clinic?.ID) return;

  const onTabChange = (tab: TabProps) => {
    if (!tab.link || !tab.id) return;
    //menu toggle
    if (tab.id === 'menu-tab') {
      setSidebarVisible(true);
      setIsMenuCollapsed(false);

      return;
    }

    setSelectedTab(tab.id);
    navigate(tab.link);
  };

  const clinicTabs = [
    {
      id: 'patients-tab',
      label: intl.formatMessage({
        id: 'navmenu.patients',
        defaultMessage: 'Patients',
      }),
      icon: <UserIcon />,
      link: appRoutes.toClinicPatientsPage(clinic.ID),
    },
    {
      id: 'organize-patients-tab',
      label: intl.formatMessage({
        id: 'navmenu.organize',
        defaultMessage: 'Organize',
      }),
      icon: <FolderIcon />,
      link: appRoutes.managePatientsPage(clinic.ID),
      hide: !clinicHasFeature(clinic.ID, 'patient_grouping'),
    },
    {
      id: 'analytics-tab',
      label: intl.formatMessage({
        id: 'navmenu.analytics',
        defaultMessage: 'Analytics',
      }),
      icon: <AnalyticsIcon />,
      link: appRoutes.toClinicAnalytics(clinic.ID),
    },
    {
      id: 'menu-tab',
      label: intl.formatMessage({
        id: 'navmenu.menu',
        defaultMessage: 'Menu',
      }),
      icon: <BurgerIcon />,
      link: '/',
      props: {
        'data-cy': 'sidebar-toggle-mobile',
      },
    },
  ];

  return (
    <div className={styles.container}>
      {isMobile && (
        <ClinicsNavbarMobile
          clinic={{
            id: clinic.ID,
            name: clinic.post_title,
          }}
          clinicTabs={clinicTabs.filter((tab) => !tab.hide)}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
          tabTemplate={(tab, classNames) =>
            tab.link ? (
              <NavLink
                to={tab.link}
                className={({ isActive }) => classNames(isActive).join(' ')}
                onClick={(e) => e.preventDefault()}
              >
                {tab.icon}
                <span>{tab.label}</span>
              </NavLink>
            ) : (
              <></>
            )
          }
        />
      )}
    </div>
  );
}
