import useSWR from 'swr';

import { buildUrl, InsightsApi } from 'src/api/Api';
import { Clinic } from 'src/domain/Clinic';

export const CLINIC = (clinicId: string) => `/clinics/${clinicId}`;

export function useClinic(clinicId?: string) {
  const url = clinicId && buildUrl(CLINIC(clinicId.toString()));
  const { data, error, isValidating } = useSWR(
    url,
    (_url: string) => InsightsApi.get<Clinic>(_url),
    {
      revalidateOnFocus: false,
      suspense: true,
    }
  );

  return { isLoading: isValidating, clinic: data ?? null, error };
}
